.card-container-row {
    margin-left: 0px;
    width: 660px;
}

.no-request {
    width: 200px;
    margin-top: 10px;
}


.view-all-less {
    display: flex;
    float: right;
}

.subject-request-count {
    margin: 20px 0 0 0;
}