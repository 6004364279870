	.maintenance-container {
		font-family: 'Tahoma' , sans-serif; 
		color: #333;		
		margin: 0 auto;
		width:100%;
		height:100vh;
		background-color: #F7F7F7;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
	}	
	.maintenance-left-content {
		float: left;
		width:60%;
		margin-right:5%;
		text-align: center;
	}
	.maintenance-right-pattern {
		float:right;
		width:35%;		
	}
	
	@media screen and (max-width: 768px) {
	  .left-content { 
		width: 100%;
	  }
	  .right-pattern {
		float: left;
		width: 100%;
	  }
	}
    .error-template
    {
        padding: 40px 15px;
        text-align: center;		
    }
    .error-actions
    {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .error-actions .btn
    {
        margin-right: 10px;
    }
   
	.error-template h1, .h1 {
		font-size:33px;
		font-weight:300;
	}
	
	.error-template h2, .h2 {
		margin-top: 40px;
		margin-bottom:40px;
		font-size: 40px;
		font-weight:300;
	}
	.error-template h4, .h4 {
		line-height: 1.5;
		font-size: 18px;
		font-weight:300;
	}
	
	h2:after {
		content:" ";
		margin: 15px auto;;
		width:100px;
		height:2px;
		background: #0087A7;
		display:block;
	}
	.text-props {
		width: 85%;
		margin:0 auto;
	}
	.logo-svg {
		margin:0 auto; 
		padding-top: 10px;
	}
	.maintenance-countdown {
		display:block;
		border-bottom: solid 1px deepskyblue;  
		padding-top: 4px;
		max-width:100%;
		animation: drawBorder 183s ease-in forwards;  
	}
	.maintenance-logout {
		color: royalblue;
		float: right;
		margin: 0px 15px;
		cursor: pointer;
		width: 50px;
	}