.impersonating-banner {
    background-color: #FAEBC3;
    padding: 25px 50px 25px 50px;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
}

.impersonating-link {
    float:left;
}

.impersonating-header{ 
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin: 0px 150px 0px 0px;
}