
  .uploadContainer {
    border: 1px solid white;
    height: auto;
    width: 100%;
    float: left;
    border-radius: 4px;   
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }
  
  .uploadHeader {
    height: 54px;
    float: left;
    background: white;
    width: 100%;
    box-shadow: 0px 1px;
  }
  .uploadcheckbox {
    float: left;
    width: 1.5%;
    background: rgb(255, 255, 255);
    margin-top: 16px;
    margin-left: 10px;
    height: 23px;
  }
  
  .uploadtext {
    float: left;
    width: 90%;
    padding-top: 16px;
    text-align: left;
    margin-left: 20px;
  }
  .uploaddragzone {
    margin: 77px 25px 26px 25px;
    height: 58px;
    background: #ccc;
  }
  
  .dragzone {
    border: 1px dashed grey;
    padding: 4px;
  }
  
  .isDragActive {
    background-color: #d9d9d9;
  }
  .filesUl {
    list-style-type: none;
    width: 100%;
    /* float: left; */
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
  .fileList {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px;
  }
  .removeIcon {
    width: 20px;
    padding: 0px 10px 0px 0px;
  }
  .threedotsIcon {
    width: 20px;
    padding: 17px 10px 0px 0px;
  }
  
  .upload-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .upload-display {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
  }
  
  .file-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: initial;
  }
  
  .cancel {
    margin-top: 10px;
  }
  
  .upload-containter {
    margin-top: 80px;
  }
  
  .tooltipInit {
    text-align: center;
  }
  
  .cancel-all {
    text-align: center;
  }

.fileUploadContainter{
  margin-top: 35px;
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.fileDisplayContainer{
  width: 100%;
    float: left;
    padding: 0px 2%;
}
.fileUiWrapper{
  width: 100%;
    float: left;
}
.filenameDisplay{
  width: 25%;
    float: left;
    font-size: 14px;
}

.uploadFileNameTxt{
  font-size: 14px;
  color:#6a7070;
}

.uploadFileSizeTxt{
  font-size: 14px;
  color:#a9a9a9;
}
.singleFileUpload{
  width: 100%;
    float: left;
}
.removeImageWrapper{
  float: left;
   
}

.uploadFileNameWrapper{
  float: left; 
  padding-left: 0px;
  position: relative;
  top: -3px;
  height: 70px;
  width: 93%;
 }


.scanningTxt{
  padding-left: 10px;
  color: orange;
  font-weight: bold;
}
.acceptedTxt {
  padding-left: 10px;
  color: green;
  font-weight: bold;
}
.rejectedTxt {
  padding-left: 10px;
  color: red;
  font-weight: bold;
}
.blue-bg {
  background: #ACDCF5;
}

.text-weight-700 {
  font-weight: 700;
}
