.app-container {
  max-width: 1280px;
  margin: auto;
  position: relative;
}

.App {
  text-align: center;
  display: inline;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-tab {
  top: 80px;
  background-color: #005a70;
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  padding-left: 20px;
  position: fixed;
  z-index: 99;  
}
.App-header {
  background-color: #fff;
  display: table-cell;
  padding-top: 25px;
  padding-left: 25px;
}

.App-signout {
  float: right;
  height: 60px;
  padding: 33px 13px;
  color: #6a7070;
  /* cursor: pointer; */
  font-size: 17px;
  /* padding-top: 24px; */
}

.App-menu-bar {
  margin-top: -8px;
  margin-left: -28px;
  color: #fff;
  list-style-type: none;
  float: left;
  border-bottom-color: #FFF;
  cursor: pointer;
}
.App-signout:hover {
  color: black

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
