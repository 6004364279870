.content {
  padding-top: 76px;
  
}
.uploadContainer {
  border: 1px solid lightblue;
  height: auto;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.uploadHeader {
  height: 54px;
  float: left;
  background: lightblue;
  width: 100%;
}
.uploadcheckbox {
  float: left;
  width: 1.5%;
  background: rgb(255, 255, 255);
  margin-top: 16px;
  margin-left: 10px;
  height: 23px;
}

.uploadtext {
  float: left;
  width: 90%;
  padding-top: 16px;
  text-align: left;
}
.uploaddragzone {
  margin: 77px 25px 26px 25px;
  height: 58px;
  background: #ccc;
}

.dragzone {
  border-style: dashed;
  border-width: 1px;
  border-color: grey;
  /* padding: 6px; */
}

.isDragActive {
  background-color: #d9d9d9;
}
.filesUl {
  list-style-type: none;
  width: 100%;
  float: left;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-flow: row wrap;
}
.fileList {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 20px;
}
.removeIcon {
  width: 20px;
  padding: 0px 10px 0px 0px;
}
.threedotsIcon {
  width: 20px;
  padding: 17px 10px 0px 0px;
}

.upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-display {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.file-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: initial;
}

.cancel {
  margin-top: 10px;
}

.upload-containter {
  margin-top: 80px;
}

.tooltipInit {
  text-align: center;
}

.cancel-all {
  text-align: center;
}