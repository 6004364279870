/* .manage-learner-banner {
    background-color:#c9dfff;
    padding: 25px 50px 25px 50px;
    width: 100vw;
    margin-left: calc(-50vw + 49.3%);
} */

.manage-learner-link {
    float:left;
    width:15%;
    line-height: 2.32pc;
    margin-top: 5px;
}

.manage-learner-header{ 
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #FFF;
    margin: 0;    
    width: 75%;
    background: #1E496C;
    float: right;
    padding: 12px 10px;
}

/* .manageLearnerHeader {
    width: 87%;
    height: 50px;
    float: left;
    background: #1E496C;
    color: #fff;
    padding-top:13px;
  }
  .MLLeftContent{
    width:80%;
    float: left;
    padding-left: 10px;
  } */

  .manage-learner-papername-prefix {
      font-weight: 100;
  }