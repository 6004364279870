.gr-grid-container-uk {
    grid-template-columns: repeat(6, 1fr);
}

.horizontal-rule {
    height: 1px;
    background-color: #6A7070;
    border: none;
}

.float-right {
    float: right;
}

.no-message { 
    text-align: center;
}

.showing-messsage {
    vertical-align: text-bottom;
    white-space: nowrap;
    padding-top: 5px;
    float: left;
}

.results-message { 
    padding-top: 10px;
}