.link-learners-link {
    float:left;
    width:15%;
    line-height: 2.32pc;
    margin-top: 5px;
}

.link-learners-link2 {
    float:left;
    /* width:15%; */
    line-height: 2.32pc;
    margin-top: 5px;
}

.link-learners-header{ 
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #FFF;
    margin: 0;    
    width: 75%;
    background: #1E496C;
    float: right;
    padding: 12px 10px;
}

.link-learners-header2{ 
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #FFF;
    margin: 0;    
    min-width: 75%;
    max-width: 83%;
    background: #1E496C;
    float: right;
    padding: 12px 10px;
}

.link-learners-papername-prefix {
    font-weight: 100;
}
.saveLinkLrBut{
    float: right;
    min-width: 110px;
    font-weight: bold;
    margin-right: 2%;
}

.guidance-msg {
    width: 80%;
    float: left;
}

.more-link {
    color:#006A93;
    cursor:pointer;
    font-weight: 640;
}

.file-dropdown {
    width:35%;
    float: left;
    margin-top: 15px;
}
