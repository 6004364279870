.shared-file-guide-msg {
    font-weight: normal;
    font-size: 9.7pt;
    padding-left: 30px;
    color: midnightblue;
}

.gold-yellow-bg {
    background-color: lightgoldenrodyellow;
}

.vdots
{
    margin-top: 0px !important;
}

div.gr-grid-container
{
    grid-row-gap: 10px;
}
div.gr-grid-container.config-screen
{
    grid-row-gap: 10px;
    margin:0
}

div.hide
{
    display: none;
}

.warningIcon
{
    color: #ffc107;
    fill: #ffc107;
}