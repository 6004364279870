div.manage-setup div.invalid-feedback
{
    display: inline !important;
    color:rgb(219, 0, 32);
    letter-spacing:0.3px;
    line-height:1.25em;
}

div.prs-invalid-feedback
{
    margin-right: 40px !important;
}

span.gr-meta
{
    vertical-align: top;
}

div.manage-setup input.gr-input
{
   color: black;
}

.error-messages
{
    margin: 0;
    list-style: none;
    padding-left:30px;
}