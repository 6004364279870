.table-menu div.vdots {
  margin: 10px 0 0 30px;
  cursor: pointer;
}

.table-menu div.vdots:after {
  content: "\2807";
  font-size: 20px;
}

.bubble {
  position: absolute;
  /* width: auto;*/
  /* min-width: 140px; */
  /* max-width: 155px; */
  height: auto;
  left: -13px;
  top: 37px;
  background: #ffffff;
  -webkit-border-radius: 1px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: #5f5f5f solid 1px;
  padding: 5px;
  /* font-family: tahoma; */
  font-size: 14px;
  z-index: 10;
}

.bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 32px;
}

.bubble:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #5f5f5f transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -16px;
  left: 32px;
}

.table-menu {
  position: relative;
}

.link-holder {
  /* width: 150px; */
  height: auto;
}
.link-holder ul {
  padding: 0;
  margin: 0;
}
.link-holder ul li {
  list-style: none;
  border-bottom: 1px #ccc solid; /*#DEE7E7*/
  line-height: 18px;
  padding: 5px 0;
}
.link-holder ul li:last-child {
  border-bottom: none;
}
.link-holder a {
  color: #555; /* #535657; */
  padding: 0 7px;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
.link-holder a:hover {
  /*color: white;
      background: #535657;*/
  display: block;
  color: #000;  /* #0D1F22; */
}

.link-holder span.disabled-link {
  padding: 0 7px;
  color: #ccc;
}

.link-holder a.disabled-link {
  display: block;
  color: #555;
  cursor:default;
} 

.link-holder a.disabled-link:hover {
  display: block;
  color: #555;
  cursor:default;
}
