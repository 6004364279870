.pageContainer {
  margin-left: 25%;
  padding: 2%;
  width: 50%;
  border: 1px solid;
  box-shadow: 5px 10px #665;
}
.pageContainer h1{
  text-align: center;
}

.pageContainer .messageText {
  text-align: justify;
}

.logoutDiv {
  text-align: center;
  padding: 0 0 2% 0;
} 

@keyframes drawBorder {
  from { max-width: 100%; }
  to { max-width: 0%; }
}

.animateBorder {
  display:block;
  border-bottom: solid 1px #bbb;  
  padding-top: 8px;
  max-width:100%;
  animation: drawBorder 30s ease-in forwards;  
  }
.logoutBtn {
    color: #fff;
    background-color: #047a9c;
    border-radius: 2px;
    text-decoration: none;
    border: 1px solid #047a9c;
    position: relative;
    cursor:"pointer";
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 15px;
    line-height: 32px;
    padding: 0 12px;
    font-weight: 500;
    font-family: Open Sans,Calibri,Tahoma,sans-serif;
    -webkit-appearance: button;
}
  