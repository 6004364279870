.loader-container {
    bottom: 0;
    display: flex;
    // height: 100vh;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
}
.loadingIcon { width: 140px} 

@-moz-document url-prefix() {   
    .loadingIcon { width: 140px} 
}

.loadingIcon { width: 140px} 

@supports (-ms-ime-align:auto) {
    .loadingIcon { width: 140px} 
}